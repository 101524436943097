import stateData from "./state";
import service from "../../api";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setExplored (state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    },
    // 设置查询版本
    setProvider (state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate (state, date) {
      state.date = date;
    }
  },
  actions: {
    getExplored ({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true
      });
      // 重置state
      commit("setExplored", {
        ...stateData
      });
      return service
        .commonRisk({
          uid,
          refresh
        }, 'EXPLORED')
        .then(res => {
          let {
            provider = "", data = {}, date = ""
          } = res || {};
          commit("setExplored", {
            provider,
            result_detail: data['EXPLORED'].data,
            date
          });
          commit("userReport/setLoading", false, {
            root: true
          });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters: {
    apply: state => {
      console.log(state)
      let data = state.result_detail.apply_report_detail
      if (!data) {
        data = {}
      }
      return [{ label: '申请准入分', val: data.A22160001 }, 
      { label: '申请准入置信度', val: data.A22160002 },
      { label: '申请命中机构数', val: data.A22160003 }, 
      { label: '申请命中消金类机构数', val: data.A22160004 },
      { label: '申请命中网络贷款类机构数', val: data.A22160005 }, 
      { label: '机构总查询次数', val: data.A22160006 },
      { label: '最近一次查询时间', val: data.A22160007 }, 
      { label: '近1个月机构总查询笔数', val: data.A22160008 },
      { label: '近3个月机构总查询笔数', val: data.A22160009 }, 
      { label: '近6个月机构总查询笔数', val: data.A22160010 }]
    },
    behavior: state => {
      let data = state.result_detail.behavior_report_detail
      if (!data) {
        data = {}
      }
      return {
        base:[{val1:data.B22170001||'-',val2:data.B22170034||'-',val3:data.B22170050||'-',val4:data.B22170051||'-',val5:data.B22170052||'-',val6:data.B22170053||'-',val7:data.B22170054||'-'}],
        month:[{name:"贷款笔数",val1:data.B22170002,val3:data.B22170003,val6: data.B22170004,val12: data.B22170005,val24: data.B22170006},
          {name:"贷款总金额",val1:data.B22170007,val3:data.B22170008,val6: data.B22170009,val12: data.B22170010,val24: data.B22170011},
          {name:"贷款金额在1k-3k的笔数",val1:'-',val3:'-',val6: '-',val12: data.B22170013,val24: '-'},
          {name:"贷款金额在3k-10k的笔数",val1:'-',val3:'-',val6: '-',val12: data.B22170014,val24: '-'},
          {name:"金额在1w以上的笔数",val1:'-',val3:'-',val6: '-',val12: data.B22170015,val24: '-'},
          {name:"贷款机构数",val1:data.B22170016,val3:data.B22170017,val6: data.B22170018,val12: data.B22170019,val24: data.B22170020},
          {name:"消金类贷款机构数",val1:'-',val3:'-',val6: '-',val12: data.B22170021,val24: data.B22170022},
          {name:"网贷类贷款机构数",val1:'-',val3:'-',val6: '-',val12: data.B22170023,val24: data.B22170024},
          {name:"M0+逾期贷款笔数",val1:'-',val3:'-',val6: data.B22170025,val12: data.B22170026,val24: data.B22170027},
          {name:"M1+逾期贷款笔数",val1:'-',val3:'-',val6: data.B22170028,val12: data.B22170029,val24: data.B22170030},
          {name:"累计逾期金额",val1:'-',val3:'-',val6: data.B22170031,val12: data.B22170032,val24: data.B22170033},
          {name:"失败扣款笔数",val1:data.B22170035,val3:data.B22170036,val6: data.B22170037,val12: data.B22170038,val24: data.B22170039},
          {name:"履约贷款总金额",val1:data.B22170040,val3:data.B22170041,val6: data.B22170042,val12: data.B22170043,val24: data.B22170044},
          {name:"履约贷款次数",val1:data.B22170045,val3:data.B22170046,val6: data.B22170047,val12: data.B22170048,val24: data.B22170049}
        ]
      }
    },
    current: state => {
      let data = state.result_detail.current_report_detail
      if (!data) {
        data = {}
      }
      return [{ label: '网贷授信额度', val: data.C22180001 }, { label: '网贷额度置信度', val: data.C22180002 },
      { label: '网络贷款类机构数', val: data.C22180003 }, { label: '网络贷款类产品数', val: data.C22180004 },
      { label: '网络贷款机构最大授信额度', val: data.C22180005 }, { label: '网络贷款机构平均授信额度', val: data.C22180006 },
      { label: '消金贷款类机构数', val: data.C22180007 }, { label: '消金贷款类产品数', val: data.C22180008 },
      { label: '消金贷款类机构最大授信额度', val: data.C22180009 }, { label: '消金贷款类机构平均授信额度', val: data.C22180010 },
      { label: '消金建议授信额度', val: data.C22180011 }, { label: '消金额度置信度', val: data.C22180012 }]
    }
  }
};
