import stateData from "./state";
import service from "../../api";

let FLAG = "SF"

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setData(state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    },
    // 设置查询版本
    setProvider(state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate(state, date) {
      state.date = date;
    }
  },
  actions: {
    getData({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true
      });
      // 重置state
      commit("setData", {
        ...stateData
      });
      return service
        .commonRisk({
          uid,
          refresh
        }, FLAG)
        .then(res => {
          let {
            provider = "", data = {}, date = ""
          } = res || {};
          commit("setData", {
            provider,
            result_detail: data,
            date
          });
          commit("userReport/setLoading", false, {
            root: true
          });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters: {
    data: state => {
      let data = state.result_detail[FLAG].data.list
      return data
    }
  }
};
