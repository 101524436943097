import stateData from "./state";
import service from "../../api";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setPortrait (state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    },
    // 设置查询版本
    setProvider (state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate (state, date) {
      state.date = date;
    }
  },
  actions: {
    getPortrait ({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true
      });
      // 重置state
      commit("setPortrait", {
        ...stateData
      });
      return service
        .commonRisk({
          uid,
          refresh
        }, 'Portrait')
        .then(res => {
          let {
            provider = "", data = {}, date = ""
          } = res || {};
          commit("setPortrait", {
            provider,
            result_detail: data['PORTRAIT'].data,
            date
          });
          commit("userReport/setLoading", false, {
            root: true
          });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters: {
    data: state => {
      let data = state.result_detail
      return [
        { label: '消费能力综合评级', val: data.consume_level },
        { label: '近一年消费主要所在地', val: data.m12_consume_addr }, 
        { label: '近1个月消费金额', val: data.m1_consume_money },
      { label: '近3个月消费金额', val: data.m3_consume_money },
       { label: '近12个月消费金额', val: data.m12_consume_money },
      { label: '近1个月消费活跃度', val: data.m1_consume_active }, 
      { label: '近3个月消费活跃度', val: data.m3_consume_active },
      { label: '近12个月消费活跃度', val: data.m12_consume_active },
       { label: '近12个月双休日消费金额', val: data.m12_consume_weekend_money },
      { label: '近12个月午夜消费金额', val: data.m12_consume_night_money },
       { label: '近12个月工作日消费金额', val: data.m12_consume_workday_money },
      { label: '近12个月整额消费金额', val: data.m12_consume_ze_money }, 
      { label: '近12个月信用卡消费金额', val: data.m12_consume_xyk_money },
      { label: '近12个月双休日消费占比', val: data.m12_consume_weekend_rate },
       { label: '近12个月午夜消费占比', val: data.m12_consume_night_rate },
      { label: '近12个月工作日消费占比', val: data.m12_consume_workday_rate }, 
      { label: '近12个月整额消费占比', val: data.m12_consume_ze_rate },
      { label: '近12个月信用卡消费占比', val: data.m12_consume_xyk_rate }, 
      { label: '近12个月最大单日消费占比', val: data.m12_consume_maxday_rate },
      { label: '近12个月房产类消费金额', val: data.m12_consume_fcl_money }, 
      { label: '近12个月汽车类消费金额', val: data.m12_consume_qcl_money }, 
      { label: '近12个月境外消费金额', val: data.m12_consume_jw_money }, ]
    }
  }
};
