export default {
  user: {},
  userInfo: {},
  desensitizationData: true,
  appInfo: [],
  userPersonal: {},
  firstLogin: {
    device: ""
  },
  mobiles: [],
  mapList: [],
  loginList: [],
  page: {},
  spirit: {},
  rangeData:{},
  riskInfo:[],
  friend:{}
};
