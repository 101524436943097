import stateData from "./state";
import service from "../../api";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setBlacklist(state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    },
    // 设置查询版本
    setProvider(state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate(state, date) {
      state.date = date;
    }
  },
  actions: {
    getBlacklist({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true
      });
      // 重置state
      commit("setBlacklist", {
        ...stateData
      });
      return service
        .commonRisk({
          uid,
          refresh
        }, 'BLACKLIST')
        .then(res => {
          let {
            provider = "", data = {}, date = ""
          } = res || {};
          commit("setBlacklist", {
            provider,
            result_detail: data,
            date
          });
          commit("userReport/setLoading", false, {
            root: true
          });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters: {
    data1: state => {
      let data = {text:'未命中',color:'#489f12'}
      if (state.result_detail['BLACKLIST']) {
        data = state.result_detail['BLACKLIST'].data.response.black_level
        data = data=='A'?{text:'A - 极黑账户',color:'#fa4947'}:data=='B'?{text:'B - 高危账户',color:'#ce760f'}:
        data=='C'?{text:'C - 关注账户',color:'#227cd7'}:data=='D'?{text:'D - 灰度账户',color:'#22c3bc'}:{text:'未命中',color:'#489f12'}
      }
      return data
    },
    data2: state => {
      let data = {}
      if (state.result_detail['OVERDUE']) {
        data = state.result_detail['OVERDUE'].data.response
      }
      return [
        { label: "贷款机构数", val1: data.xyp_cpl0070, val2: data.xyp_cpl0009, val3: data.xyp_cpl0010, val4: data.xyp_cpl0063, val5: data.xyp_cpl0011, val6: data.xyp_cpl0012, val7: data.xyp_cpl0013 },
        { label: "交易失败笔数", val1: data.xyp_cpl0016, val2: data.xyp_cpl0018, val3: data.xyp_cpl0020, val4: data.xyp_cpl0065, val5: data.xyp_cpl0022, val6: data.xyp_cpl0024, val7: data.xyp_cpl0026 },
        { label: "还款成功笔数", val1: data.xyp_cpl0017, val2: data.xyp_cpl0019, val3: data.xyp_cpl0021, val4: data.xyp_cpl0064, val5: data.xyp_cpl0023, val6: data.xyp_cpl0025, val7: data.xyp_cpl0027 },
        { label: "是否发生过逾期", val1: data.xyp_cpl0028, val2: data.xyp_cpl0029, val3: data.xyp_cpl0030, val4: data.xyp_cpl0031, val5: '-', val6: '-', val7: '-' },
        { label: "交易失败总金额", val1: data.xyp_cpl0032, val2: data.xyp_cpl0034, val3: data.xyp_cpl0036, val4: data.xyp_cpl0066, val5: data.xyp_cpl0038, val6: data.xyp_cpl0040, val7: data.xyp_cpl0042 },
        { label: "还款成功总金额", val1: data.xyp_cpl0033, val2: data.xyp_cpl0035, val3: data.xyp_cpl0037, val4: data.xyp_cpl0067, val5: data.xyp_cpl0039, val6: data.xyp_cpl0041, val7: data.xyp_cpl0043 },
        { label: "失败次数>0的机构数", val1: '-', val2: data.xyp_cpl0048, val3: data.xyp_cpl0049, val4: data.xyp_cpl0050, val5: data.xyp_cpl0051, val6: data.xyp_t03td045, val7: data.xyp_t03td053 },
        { label: "交易总金额", val1: '-', val2: data.xyp_t01acbzzz, val3: data.xyp_t01acczzz, val4: data.xyp_t01acdzzz, val5: data.xyp_t01acezzz, val6: '-', val7: data.xyp_t01acgzzz },
      ]
    },
    history: state => {
      let data = {}
      if (state.result_detail['OVERDUE']) {
        data = state.result_detail['OVERDUE'].data.response
      }
      return data
    }
  }
};
