import stateData from "./state";
import service from "../../api";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setComprehensive (state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    },
    // 设置查询版本
    setProvider (state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate (state, date) {
      state.date = date;
    }
  },
  actions: {
    getComprehensive ({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true
      });
      // 重置state
      commit("setComprehensive", {
        ...stateData
      });
      return service
        .commonRisk({
          uid,
          refresh
        }, 'COMPREHENSIVE')
        .then(res => {
          let {
            provider = "", data = {}, date = ""
          } = res || {};
          commit("setComprehensive", {
            provider,
            result_detail: data.result_detail,
            date
          });
          commit("userReport/setLoading", false, {
            root: true
          });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters: {
    comprehensives: state => {
      let data =  state.result_detail
      return [
        {
          name: "贷款行为分",
          value: data.B22170001,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近1个月贷款笔数",
          value: data.B22170002,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近3个月贷款笔数",
          value: data.B22170003,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近6个月贷款笔数",
          value: data.B22170004,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月贷款笔数",
          value: data.B22170005,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近24个月贷款笔数",
          value: data.B22170006,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近1个月贷款总金额",
          value: data.B22170007,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近3个月贷款总金额",
          value: data.B22170007,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近6个月贷款总金额",
          value: data.B22170009,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月贷款总金额",
          value: data.B22170010,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近24个月贷款总金额",
          value: data.B22170011,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月贷款金额在1k及以下的笔数",
          value: data.B22170012,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月贷款金额在1k-3k的笔数",
          value: data.B22170013,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月贷款金额在3k-10k的笔数",
          value: data.A22160014,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月贷款金额在1w以上的笔数",
          value: data.B22170015,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近1个月贷款机构数",
          value: data.B22170016,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近3个月贷款机构数",
          value: data.B22170017,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近6个月贷款机构数",
          value: data.B22170018,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月贷款机构数",
          value: data.B22170019,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近24个月贷款机构数",
          value: data.B22170020,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月消金类贷款机构数",
          value: data.B22170021,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近24个月消金类贷款机构数",
          value: data.B22170022,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月网贷类贷款机构数",
          value: data.B22170023,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近24个月网贷类贷款机构数",
          value: data.B22170024,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近6个月M0+逾期贷款笔数",
          value: data.B22170025,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月M0+逾期贷款笔数",
          value: data.B22170026,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近24个月M0+逾期贷款笔数",
          value: data.B22170027,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近6个月M1+逾期贷款笔数",
          value: data.B22170028,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月M1+逾期贷款笔数",
          value: data.B22170029,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近24个月M1+逾期贷款笔数",
          value: data.B22170030,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近6个月累计逾期金额",
          value: data.B22170031,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月累计逾期金额",
          value: data.B22170032,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近24个月累计逾期金额",
          value: data.B22170033,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "正常还款订单数占贷款总订单数比例",
          value: data.B22170034,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近1个月失败扣款笔数",
          value: data.B22170035,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近3个月失败扣款笔数",
          value: data.B22170036,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近6个月失败扣款笔数",
          value: data.B22170037,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月失败扣款笔数",
          value: data.B22170038,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近24个月失败扣款笔数",
          value: data.B22170039,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近1个月履约贷款总金额",
          value: data.B22170040,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近3个月履约贷款总金额",
          value: data.B22170041,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近6个月履约贷款总金额",
          value: data.B22170042,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月履约贷款总金额",
          value: data.B22170043,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近24个月履约贷款总金额",
          value: data.B22170044,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近1个月履约贷款次数",
          value: data.B22170045,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近3个月履约贷款次数",
          value: data.B22170046,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近6个月履约贷款次数",
          value: data.B22170047,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近12个月履约贷款次数",
          value: data.B22170048,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "近24个月履约贷款次数",
          value: data.B22170049,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "最近一次履约距今天数",
          value: data.B22170050,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "贷款行为置信度",
          value: data.B22170051,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "贷款已结清订单数",
          value: data.B22170052,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "信用贷款时长",
          value: data.B22170053,
          color: "rgba(64, 158, 255, 1)"
        },
        {
          name: "最近一次贷款放款时间",
          value: data.B22170054,
          color: "rgba(64, 158, 255, 1)"
        }
      ]
    }
  }
};
