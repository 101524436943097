import stateData from "./state";
import service from "../../api";

export default {
  namespaced: true,
  state: {
    ...stateData
  },
  mutations: {
    setBodyguard (state, data = {}) {
      for (let key in data) {
        state[key] = data[key] || "";
      }
    },
    // 设置查询版本
    setProvider (state, provider = "") {
      state.provider = provider || "";
    },
    //刷新时间
    setRefreshDate (state, date) {
      state.date = date;
    }
  },
  actions: {
    getBodyguard ({
      rootState,
      commit
    }, refresh) {
      let {
        uid = ""
      } = rootState.userReport;
      commit("userReport/setLoading", true, {
        root: true
      });
      // 重置state
      commit("setBodyguard", {
        ...stateData
      });
      return service
        .commonRisk({
          uid,
          refresh
        }, 'BODYGUARD')
        .then(res => {
          let {
            provider = "", data = {}, date = ""
          } = res || {};
          commit("setBodyguard", {
            provider,
            result_detail: data['BODYGUARD'].data.result_desc,
            date
          });
          commit("userReport/setLoading", false, {
            root: true
          });
          return true;
        })
        .catch(() => {
          commit("userReport/setLoading", false, {
            root: true
          });
          return false;
        });
    }
  },
  getters: {
    data: state => {
      return state.result_detail.ANTIFRAUD
    },
    data1: state =>{
      if(state.result_detail.ANTIFRAUD){
        let data = state.result_detail.ANTIFRAUD
        return [{final_score:data.final_score,final_decision:data.final_decision}]
      }
      return []
    },
    data2: state =>{
      if(state.result_detail.ANTIFRAUD){
        let arr = state.result_detail.ANTIFRAUD.risk_items.map((it,index)=>{
            return {...it,index:index+1}
        })
        console.log(arr)
        return arr
      }
      return []
    }
  }
};
