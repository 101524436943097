import Vue from "vue";
import VueRouter from "vue-router";

const Index = () => import("../views/home/index.vue");
const login = () => import("../views/login/index.vue");
const tips = () => import("../views/login/tips.vue"); //微信打开提示

const register = () => import("@/views/login/register.vue");
const control = () => import("@/views/control/index.vue");
const Blacklist = () => import("@/views/blacklist"); //黑名单
const riskSearch = () => import("@/views/risk");
const riskDetail = () => import("@/components/viewBar");
const preview = () => import("@/components/viewBar/preview.vue");
const userManager = () => import("@/views/account/role/index");
const account = () => import("@/views/account/list/index");
const charge = () => import("@/views/charge/index"); // 购买报告
const pay = () => import("@/views/common/pay"); // 收银台
const paySuccess = () => import("@/views/common/pay/success"); // 支付成功页
const financePurchase = () => import("@/views/finance/purchase/index"); // 购买报告列表
const financeDetail = () => import("@/views/finance/detail/index"); // 报告明细
const financeConsume = () => import("@/views/finance/consume/index"); // 消费统计
const really = () => import("@/views/really"); // 真假财务
const firstCheck = () => import("@/views/order/firstCheck"); // 初审列表
const secondCheck = () => import("@/views/order/secondCheck"); // 待还列表
const unpaid = () => import("@/views/order/unpaid"); // 复审列表
const paid = () => import("@/views/order/paid"); // 回款记录
const refused = () => import("@/views/order/refused"); // 已拒订单
const renewal = () => import("@/views/order/renewal"); // 续期记录
const keyWords = () => import("@/views/userSetting/keyWords"); //  关键词设置
const feedBack = () => import("@/views/userSetting/feedBack"); //  意见反馈
const registerTitle = () => import("@/views/userSetting/registerTitle"); //  意见反馈
const productSet = () => import("@/views/userSetting/productSet"); //  意见反馈
const programme = () => import("@/views/userSetting/programme"); //  方案管理
const promoteSet = () => import("@/views/promote/setting"); //  我要推广
const updateRecord = () => import("@/views/updateRecord/index.vue"); //  更新记录
const manual = () => import("@/views/manual/index.vue"); //  操作手册
const policySet = () => import("@/views/userSetting/policySet"); //  决策设置

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: login
  },
  {
    path: "/tips",
    name: "tips",
    component: tips
  },
  {
    path: "/register",
    name: "register",
    component: register
  },
  {
    path: "/pay",
    name: "pay",
    component: pay
  },
  {
    path: "/",
    component: Index,
    redirect: "/control",
    children: [
      {
        path: "/control",
        name: "control",
        component: control,
        meta: {
          title: "控制台",
          bread: [
            {
              path: "/control",
              name: "控制台"
            }
          ] //  面包屑导航
        }
      },
      {
        path: "/risk",
        name: "risk-search",
        component: riskSearch,
        meta: {
          title: "查询列表",
          bread: [
            {
              path: "/risk",
              name: "信用速查"
            }
          ]
        }
      },
      {
        path: "/userManager",
        name: "user-manager",
        component: userManager,
        meta: {
          title: "角色管理",
          bread: [
            {
              path: "/userManager",
              name: "账号管理"
            },
            {
              path: "/userManager",
              name: "角色管理"
            }
          ]
        }
      },
      {
        path: "/account",
        name: "account",
        component: account,
        meta: {
          title: "账号列表",
          bread: [
            {
              path: "/userManager",
              name: "账号管理"
            },
            {
              path: "/account",
              name: "账号列表"
            }
          ]
        }
      },
      {
        path: "/blacklist",
        name: "blacklist",
        component: Blacklist,
        meta: {
          title: "黑名单库",
          bread: [
            {
              path: "/blacklist",
              name: "黑名单库"
            }
          ]
        }
      },
      // {
      //   path: "/really",
      //   name: "really",
      //   component: really,
      //   meta: {
      //     title: "真假财务",
      //     bread: [
      //       {
      //         path: "/really",
      //         name: "真假财务"
      //       }
      //     ]
      //   }
      // },
      {
        path: "/charge",
        name: "charge",
        component: charge,
        meta: {
          title: "充值"
        }
      },
      {
        path: "/pay/success",
        name: "pay-success",
        component: paySuccess,
        meta: {
          title: "支付成功"
        }
      },
      {
        path: "/finance/purchase",
        name: "finance-purchase",
        component: financePurchase,
        meta: {
          title: "充值明细",
          bread: [
            {
              path: "/finance/purchase",
              name: "财务统计"
            },
            {
              path: "/finance/purchase",
              name: "充值记录"
            }
          ]
        }
      },
      {
        path: "/finance/detail",
        name: "finance-detail",
        component: financeDetail,
        meta: {
          title: "报告明细",
          bread: [
            {
              path: "/finance/purchase",
              name: "财务统计"
            },
            {
              path: "/finance/detail",
              name: "查询明细"
            }
          ]
        }
      },
      {
        path: "/finance/consume",
        name: "finance-consume",
        component: financeConsume,
        meta: {
          title: "报告明细",
          bread: [
            {
              path: "/finance/purchase",
              name: "财务统计"
            },
            {
              path: "/finance/consume",
              name: "消费统计"
            }
          ]
        }
      },
      {
        path: "/order/firstCheck",
        name: "order-firstCheck",
        component: firstCheck,
        meta: {
          title: "初审列表",
          bread: [
            {
              path: "/order/firstCheck",
              name: "订单管理"
            },
            {
              path: "/order/firstCheck",
              name: "初审列表"
            }
          ]
        }
      },
      {
        path: "/order/secondCheck",
        name: "order-secondCheck",
        component: secondCheck,
        meta: {
          title: "复审列表",
          bread: [
            {
              path: "/order/firstCheck",
              name: "订单管理"
            },
            {
              path: "/order/secondCheck",
              name: "复审列表"
            }
          ]
        }
      },
      {
        path: "/order/unpaid",
        name: "order-unpaid",
        component: unpaid,
        meta: {
          title: "待还列表",
          bread: [
            {
              path: "/order/firstCheck",
              name: "订单管理"
            },
            {
              path: "/order/unpaid",
              name: "待还列表"
            }
          ]
        }
      },
      {
        path: "/order/paid",
        name: "order-paid",
        component: paid,
        meta: {
          title: "回款记录",
          bread: [
            {
              path: "/order/firstCheck",
              name: "订单管理"
            },
            {
              path: "/order/paid",
              name: "回款记录"
            }
          ]
        }
      },
      {
        path: "/order/refused",
        name: "order-refused",
        component: refused,
        meta: {
          title: "已拒订单",
          bread: [
            {
              path: "/order/firstCheck",
              name: "订单管理"
            },
            {
              path: "/order/refused",
              name: "已拒订单"
            }
          ]
        }
      },
      {
        path: "/order/renewal",
        name: "order-renewal",
        component: renewal,
        meta: {
          title: "续期记录",
          bread: [
            {
              path: "/order/firstCheck",
              name: "订单管理"
            },
            {
              path: "/order/renewal",
              name: "续期记录"
            }
          ]
        }
      },
      {
        path: "/setting/keywords",
        name: "setting-keywords",
        component: keyWords,
        meta: {
          title: "关键词设置",
          bread: [
            {
              path: "/setting/keywords",
              name: "系统设置"
            },
            {
              path: "/setting/keywords",
              name: "关键词"
            }
          ]
        }
      },
      {
        path: "/setting/feedBack",
        name: "setting-feedBack",
        component: feedBack,
        meta: {
          title: "意见反馈",
          bread: [
            {
              path: "/setting/keyWords",
              name: "系统设置"
            },
            {
              path: "/setting/feedBack",
              name: "意见反馈"
            }
          ]
        }
      },
      {
        path: "/setting/registerTitle",
        name: "setting-registerTitle",
        component: registerTitle,
        meta: {
          title: "基础设置",
          bread: [
            {
              path: "/setting/keyWords",
              name: "系统设置"
            },
            {
              path: "/setting/registerTitle",
              name: "基础设置"
            }
          ]
        }
      },
      {
        path: "/setting/productSet",
        name: "setting-productSet",
        component: productSet,
        meta: {
          title: "产品设置",
          bread: [
            {
              path: "/setting/keyWords",
              name: "系统设置"
            },
            {
              path: "/setting/productSet",
              name: "产品设置"
            }
          ]
        }
      },
      {
        path: "/setting/programme",
        name: "setting-programme",
        component: programme,
        meta: {
          title: "方案设置",
          bread: [
            {
              path: "/setting/keyWords",
              name: "系统设置"
            },
            {
              path: "/setting/programme",
              name: "方案设置"
            }
          ]
        }
      },
      {
        path: "/setting/policySet",
        name: "setting-policySet",
        component: policySet,
        meta: {
          title: "决策设置",
          bread: [
            {
              path: "/setting/keyWords",
              name: "系统设置"
            },
            {
              path: "/setting/policySet",
              name: "决策设置"
            }
          ]
        }
      },
      {
        path: "/updateRecord",
        name: "updateRecord",
        component: updateRecord,
        meta: {
          title: "更新记录",
          bread: [
            {
              path: "/updateRecord",
              name: "更新记录"
            }
          ]
        }
      },
    ]
  },
  {
    path: "/manual",
    name: "manual",
    component: manual,
    meta: {
      title: "新手引导"
    }
  },
  {
    path: "/risk/detail",
    name: "risk",
    component: riskDetail,
    meta: {
      title: "查看报告"
    }
  },
  {
    path: '/pdf/:pdfUrl', // pdfUrl是动态路由参数
    name: "pdf",
    component: preview,
    meta: {
      title: "查看报告明细"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// 全局路由守卫
router.beforeEach((to, from, next) => {
  const LOGINURL = "/login";
  const defaultTitle = "商户管理后台";
  document.title = to.meta.title ? to.meta.title : defaultTitle;
  let url = to.path;
  let token = sessionStorage.getItem("token");

  // 判断是否登录
  if (!token && url !== LOGINURL && url !== "/register" && url !== "/tips") {
    next({
      path: LOGINURL
    });
  } else {
    next();
  }
});

export default router;
